<template>
  <div>
    <!-- testimonial -> referneic -->
    <!-- #hero -->
    <div id="hero" class="cover" data-image-src="assets/img/bg/webpartner-program.jpg">
      <div class="_wrapper">

          <h1 v-translate>homepage_heading_h1</h1>

          <router-link :to="{name: 'how-it-works'}" class="bttn -orange -shadow"><translate>Zistiť viac</translate> <i class="icon icofont-long-arrow-right"></i></router-link>

      </div>
    </div>
    <!-- /#hero -->

    <!-- .section -->
    <section class="section">
      <div class="container">

        <!-- ._header -->
        <header class="_header text-center">
          <h2 v-translate>Komu sa to oplatí?</h2>
        </header>
        <!-- /._header -->

        <!-- ._body -->
        <div class="_body">

          <!-- .icon-boxes -->
          <div class="icon-boxes">
            <div class="row">
              <div class="col-md-4">

                <!-- .icon-box -->
                <div class="icon-box">
                  <div class="-icon">
                    <img src="assets/img/icons/ico_web.svg" v-bind:alt="$gettext('Tvorcovia a správcovia webov')">
                  </div>
                  <div class="-content">
                    <h3 v-translate>Tvorcovia a správcovia webov</h3>
                    <p v-translate>Ak si zarábate tvorbou a správou stránok, môžete svoje zisky o niečo zvýšiť aj poskytovaním webhostingu v rámci vašej služby.</p>
                  </div>
                </div>
                <!-- /.icon-box -->

              </div>
              <div class="col-md-4">

                <!-- .icon-box -->
                <div class="icon-box">
                  <div class="-icon">
                    <img src="assets/img/icons/ico_agentury.svg" v-bind:alt="$gettext('Reklamné agentúry')">
                  </div>
                  <div class="-content">
                    <h3 v-translate>Reklamné agentúry</h3>
                    <p v-translate>Či už vaši klienti potrebujú spoľahlivý chod webu alebo aplikácie, bez hostingu sa nezaobídu. Poskytnite ho priamo vy a ešte na tom zarobte.</p>
                  </div>
                </div>
                <!-- /.icon-box -->

              </div>
              <div class="col-md-4">

                <!-- .icon-box -->
                <div class="icon-box">
                  <div class="-icon">
                    <img src="assets/img/icons/ico_ITfirmy.svg"  v-bind:alt="$gettext('IT outsourcing firmy')">
                  </div>
                  <div class="-content">
                    <h3 v-translate>IT outsourcing firmy</h3>
                    <p v-translate>Ideálne ak outsourcujete IT služby vrátane webhostingov, virtuálnych serverov a ich správy.</p>
                  </div>
                </div>
                <!-- /.icon-box -->

              </div>
            </div>
          </div>
          <!-- /.icon-boxes -->

        </div>
        <!-- /._body -->

      </div>
    </section>
    <!-- /.section -->

    <!-- .section -->
    <section class="section">
      <div class="container">

        <!-- ._header -->
        <header class="_header text-center">
          <h2 v-translate>Možnosti spolupráce</h2>
        </header>
        <!-- /._header -->

        <!-- ._body -->
        <div class="_body">

          <!-- .image-box -->
          <div class="image-box cover" data-image-src="assets/img/bg/box01.jpg">

            <h3 v-translate>Virtual Provider</h3>

            <div class="row">
              <div class="col-md-6">

                <p><strong v-translate>Nakupujte naše služby a produkty vo výraznej zľave a predávajte ich pod vlastnou značkou</strong></p>

              </div>
              <div class="col-md-6">

                <p v-translate>Nákup domén a hostingu za veľkoobchodné ceny. Čím viac služieb, tým väčšie zľavy. Koncoví klienti platia vami zvolenú cenu priamo vám.</p>

              </div>
            </div>

            <ul class="bttns">
                <router-link :to="{name: 'registration'}" tag="li"><a target="_self" v-bind:title="$gettext('Stať sa partnerom')" class="bttn -orange -small" v-translate>Stať sa partnerom</a></router-link>
                <router-link :to="{name: 'how-it-works'}" tag="li"><a target="_self" v-bind:title="$gettext('Zistiť viac')" class="bttn -orange -outline -small" v-translate>Zistiť viac</a></router-link>
            </ul>

          </div>
          <!-- /.image-box -->

          <!-- .image-box -->
          <div class="image-box -small cover" data-image-src="assets/img/bg/box02.jpg">

            <div class="row">
              <div class="col-md-4 col-lg-4">

                <h3 v-translate>Affiliate</h3>
                <p><strong v-translate>Získajte províziu zo zákazníkov, ktorých nám odporučíte</strong></p>

              </div>
              <div class="col-md-5 col-lg-6">

                <p>
                  <span v-translate>Provízia za každú novú objednávku a jej predlženie.</span><br>
                  <span v-translate>Vyplatenie už od 50.</span> <br>
                  <span v-translate>Bez starostí s fakturáciou a podporou klientov.</span>
                </p>

              </div>
              <div class="col-md-3 col-lg-2">

                  <a href="https://wy.sk/spolupraca/affiliate/" target="_blank" v-bind:title="$gettext('Zistiť viac')" class="bttn -orange -outline -small" v-translate>Zistiť viac</a>

              </div>
            </div>
          </div>
          <!-- /.image-box -->

          <!-- .partners -->
          <div class="partners">

            <h3 v-translate>Na technologickiej infraštruktúre od WY budú vaši klienti v skvelých rukách. Našim službám a produktom dôverujú aj:</h3>

            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide">

                  <!-- .partner -->
                  <div class="partner">
                    <div class="_wrapper">

                      <img src="assets/img/logos/ambitas.png" alt="">

                    </div>
                  </div>
                  <!-- /.partner -->

                </div>
                <div class="swiper-slide">

                  <!-- .partner -->
                  <div class="partner">
                    <div class="_wrapper">

                      <img src="assets/img/logos/pravdask.png" alt="">

                    </div>
                  </div>
                  <!-- /.partner -->

                </div>
                <div class="swiper-slide">

                  <!-- .partner -->
                  <div class="partner">
                    <div class="_wrapper">

                      <img src="assets/img/logos/radioexpres.png" alt="">

                    </div>
                  </div>
                  <!-- /.partner -->

                </div>
                <div class="swiper-slide">

                  <!-- .partner -->
                  <div class="partner">
                    <div class="_wrapper">

                      <img src="assets/img/logos/techbox.png" alt="">

                    </div>
                  </div>
                  <!-- /.partner -->

                </div>
                <div class="swiper-slide">

                  <!-- .partner -->
                  <div class="partner">
                    <div class="_wrapper">

                      <img src="assets/img/logos/galton.svg" alt="">

                    </div>
                  </div>
                  <!-- /.partner -->

                </div>
              </div>
            </div>

          </div>
          <!-- /.partners -->

        </div>
        <!-- /._body -->

      </div>
    </section>
    <!-- /.section -->

    <!-- .section -->
    <section class="section -grey">
      <div class="container">

        <!-- ._header -->
        <header class="_header text-center">
          <h2 v-translate>Máte otázky? Radi vám odpovieme.</h2>
          <p v-translate>Nenašli ste všetky potrebné informácie na tejto stránke? Napíšte nám a my sa Vám radi ozveme späť.</p>
        </header>
        <!-- /._header -->

        <!-- ._body -->
        <div class="_body">
          <div class="row">
            <div class="col-md-4">

              <!-- .contact-box -->
              <div class="contact-box">

                <h3 v-translate>Všeobecné informácie:</h3>

                <a class="tel" href="tel:0258101062" target="_blank"><i class="icon icofont-headphone-alt-2"></i> 02 - 581 010 62</a>
                <a class="mail" href="mailto:info@webpartner.sk" target="_blank"><i class="icon icofont-envelope-open"></i> info@webpartner.sk</a>

              </div>
              <!-- /.contact-box -->

              <!-- .contact-box -->
              <div class="contact-box">

                <h3 v-translate>Zákaznícka podpora</h3>

                <a class="tel" href="tel:0258101062" target="_blank"><i class="icon icofont-headphone-alt-2"></i> 02 581 010 62</a>
                <a class="mail" href="mailto:helpdesk@wy.sk" target="_blank"><i class="icon icofont-envelope-open"></i> helpdesk@wy.sk</a>

              </div>
              <!-- /.contact-box -->

            </div>
            <div class="col-md-6 col-md-offset-1">



              <!-- .form -->
              <form class="form"  @submit.prevent="submitForm">
                <div class="row">
                  <div class="col-md-6">

                    <label v-translate>Vaše meno</label>
                    <input type="text" name="name" v-model="data.name" v-validate="{required: true}">
                    <span class="reg_error">{{ errors.first('name') }}</span>

                  </div>
                  <div class="col-md-6">

                    <label v-translate>Váš e-mail</label>
                    <input type="email" name="email" v-model="data.email" v-validate="{required: true, email: true}">
                    <span class="reg_error">{{ errors.first('email') }}</span>

                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">

                    <label v-translate>Predmet správy:</label>
                    <input type="text" name="subject" v-model="data.subject" v-validate="{required: true}">
                    <span class="reg_error">{{ errors.first('subject') }}</span>

                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">

                    <label v-translate>Správa:</label>
                    <textarea name="text" v-model="data.text" v-validate="{required: true}"></textarea>
                    <span class="reg_error">{{ errors.first('text') }}</span>

                  </div>
                </div>

                <input type="submit" class="alt smaller" v-bind:value="$gettext('odoslať formulár')">
                <span class="reg_error" v-translate v-if="data.error_form_sending">Formulár sa nepodarilo odoslať</span>
                <span class="reg_error" v-translate v-if="data.error_form_validating">Formulár sa nepodarilo validovať</span>

              </form>
              <!-- /.form -->

            </div>
          </div>
        </div>
        <!-- /._body -->

      </div>
    </section>
    <!-- /.section -->
  </div>
</template>

<script>


export default {
  name: 'home',
    metaInfo () {
        return {
            title: this.$gettext('homepage_title'),
            titleTemplate: '%s | Webpartner',
            meta: [
                { name: 'description', content: this.$gettext('homepage_meta_description') }
            ],
            htmlAttrs: {
                lang: 'sk-SK',
                amp: undefined
            },
            link: [
                {rel: 'canonical', href: 'https://webpartner.sk/'}
            ]
        }
    },
    data() {
        return {
            data: {
                error_form_sending: false,
                error_form_validating: false
            },
        }
    },
    mounted: function() {
        const dictionary = {
            en: {
                messages:{
                    required: this.$gettext('validator_required'),
                    email: this.$gettext('validator_email'),
                },
            },
        };
        this.$validator.localize(dictionary)
    },
    methods: {
        submitForm: function () {
            const axios = require('axios');
            this.$validator.validateAll().then((result) => {
                if(result) {
                    axios.post("https://api.controlpanel.sk/wp-send-request", this.data).then(response => {
                        if(response.data.success == true) {
                            this.data.error_form_sending = false;
                            this.$router.push({name: 'message-sent'});
                        } else {
                            this.data.error_form_sending = true;
                        }
                    }).catch(() => {
                        this.data.error_form_sending = true;
                    });
                }
            }).catch(() => {
                this.data.error_form_validating = true;
            });
        }
    }
}
</script>
